import React from "react";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import AiChatDialogContent from "./ai-chat-dialog-content";
import AiChatFeedback from "./ai-chat-feedback";

const PaperComponent = (props) => {
  return <Paper {...props} />;
};

const AiChatDialog = (props) => {
  const [chatDialogOpen, setChatDialogOpen] = React.useState(true);
  const [hasHadDialogue, setHasHadDialogue] = React.useState(false);
  const [feedbackOpen, setFeedbackOpen] = React.useState(false);

  const handleClose = () => {
    setChatDialogOpen(false);
    if (hasHadDialogue) {
      setFeedbackOpen(true);
      setHasHadDialogue(false);
      return;
    } else {
      props.onClose && props.onClose();
    }
  };

  if(window && window.location && window.location.pathname && window.location.pathname.includes('/web-software-development') || window.location.pathname.includes('/device-agnostic-design')) {
    return (
      <Dialog
        PaperProps={{
          sx: {
            width: "90%",
            maxWidth: "90%!important",
            height: "fit-content",
            maxHeight: "80vh!important",
          },
        }}
        PaperComponent={PaperComponent}
        open={chatDialogOpen}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        disableEnforceFocus={true}
        disableScrollLock={true}
      >
          <Typography sx={{ p: 2, m:2 }}>
            The chatbot is not available in the unsupported course versions.
        </Typography>
      </Dialog>
    );
  }

  const onMessageSend = () => {
    setHasHadDialogue(true);
  };

  if (feedbackOpen) {
    return (
      <AiChatFeedback
        onClose={() => {
          setFeedbackOpen(false);
          props.onClose && props.onClose();
        }}
      />
    );
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "90%",
          maxWidth: "90%!important",
          height: "fit-content",
          maxHeight: "80vh!important",
        },
      }}
      PaperComponent={PaperComponent}
      open={chatDialogOpen}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      disableEnforceFocus={true}
      disableScrollLock={true}
    >
      <AiChatDialogContent
        onMessageSend={onMessageSend}
        handleClose={handleClose}
        courseSlug={props.courseSlug}
      />
    </Dialog>
  );
};

export default AiChatDialog;
